header {
    height: 100vh;
    padding-top: 7rem;
    overflow: hidden;

}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

.header h1{
    color: var(--color-yellow);
    text-align: center;
}

/* ====== CTA ====== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ====== HEADER SOCIALS ====== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: 0;
    bottom: 0rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 0rem;
    background: var(--color-white);
}

/* ======= ME ====== */
.me {
    background: linear-gradient(var(--color-bg-variant), transparent);
    /* background: var(--color-primary); */
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 12rem 12rem;
    overflow: hidden;
    /* padding: 5rem 1.5rem 1.5rem 1.5rem; */
}

.me__one {
    border-radius: 2rem 2rem 2rem 2rem;
    transform: rotate(10deg);
    transition: var(--transition);
    overflow: hidden;
    position: absolute;
    
}

.me__onebackground {
    width: 22rem;
    height: 22rem;
    margin-top: 5rem;
    aspect-ratio: 1/1;
    /* border-radius: 2rem 2rem; */
    background: linear-gradient(
      45deg,
      transparent,
      var(--color-yellow),
      transparent
    );
    display: flex;
    /* place-items: center; */
    left: calc(85% - 11rem);
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  
  
  .me__one:hover {
    transform: rotate(360deg);
  }

  .me__two {
    border-radius: 2rem 2rem 2rem 2rem;
    transform: rotate(-10deg);
    transition: var(--transition);
    overflow: hidden;
    position: absolute;
    
}

.me__twobackground {
    width: 22rem;
    height: 22rem;
    margin-top: 5rem;
    aspect-ratio: 1/1;
    /* border-radius: 2rem 2rem; */
    background: linear-gradient(
      140deg,
      transparent,
      var(--color-yellow),
      transparent
    );
    display: flex;
    /* place-items: center; */
    left: calc(15% - 11rem);
    position: absolute;
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }
  
  
  .me__two:hover {
    transform: rotate(360deg);
  }

/* ====== SCROLL DOWN ====== */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/*======================= MEDIA QUERIES (MEDIUM DEVICES) =======================*/
@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }

    .me__onebackground {
        display: none;
    }

    .me__twobackground {
        display: none;
    }
}

/*======================= MEDIA QUERIES (SMALL DEVICES) =======================*/
@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }

    .me__onebackground {
        display: none;
    }

    .me__twobackground {
        display: none;
    }
}


